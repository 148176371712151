import React, { useState, useEffect } from "react";
import ClayCard from "@clayui/card";
import ClayCardBody from "@clayui/card/lib/Body";
import ClayList from "@clayui/list";
import ClayButton from "@clayui/button";
import ServiceListItem from "../../../common/list/ServiceLIstItem";
import {
    REGISTRATION_SUCCESS_TITLE,
    UPGRADE_SUCCESS_TITLE,
    ACCESS_DETAILS_TITLE,
    ACCESS_DETAILS_SUBTITLE,
    REGISTRATION_SUCCESS_SUBTITLE,
    UNLOCK_ADDITIONAL_SERVICE_MESSAGE,
    ISUPPLIER_SUBMIT_MESSAGE,
} from "../../../common/constants";
import { ROUTE_PATHS } from "../../../../../../utils/constants/routeConstants";
import {
    checkUserDomain,
    getDomainErrorFlag,
    updateProfileType,
} from "../../../../../../store/Registration/profile-builder/slice";
import { useDispatch, useSelector } from "react-redux";
import { getEnvVariables } from "../../../../../../store/global/global.slice";
import { REGISTRATION, UPGRADE } from "./constants";
import { useLocation } from "react-router-dom";

const Step4 = (props: any) => {
    const location = useLocation();
    const envVariables = useSelector(getEnvVariables);
    const supportPortalUrl = envVariables?.ui.supportPortal || ROUTE_PATHS.HOME;
    const [domainChecked, setDomainChecked] = useState(false);
    const { type = "", data = [], iSupplier = false, userInfo = "", email=""} = props;
    const dispatch = useDispatch();
    const domainErrorFlag = useSelector(getDomainErrorFlag);

    useEffect(() => {
        if(domainChecked && domainErrorFlag){
            dispatch(updateProfileType(UPGRADE));
            props.setStep(5);
        }
    }, [domainErrorFlag]);

    let approvedServices = [];
    let disabledServices = [];
    let pendingServices = [];

    const services = [...data];

    if (services && services.length > 0) {
        approvedServices = services.filter((service) => service.status === "approved");
        disabledServices = services.filter((service) => service.status === "disabled");
        pendingServices = services.filter((service) => service.status === "pending");
    }
    let title = ACCESS_DETAILS_TITLE;
    switch (type) {
        case REGISTRATION:
            title = REGISTRATION_SUCCESS_TITLE;
            break;
        case UPGRADE:
            title = UPGRADE_SUCCESS_TITLE;
            break;
    }
    let subTitle = ACCESS_DETAILS_SUBTITLE;
    if (type === REGISTRATION) {
        subTitle = REGISTRATION_SUCCESS_SUBTITLE;
    }

    const initiateUserDomainCheck = () => {
        if(userInfo && (!userInfo.isBasic && !userInfo.isInternal)){
            dispatch(updateProfileType(UPGRADE));
            props.setStep(5);
        }
        else{
            dispatch(checkUserDomain(email));            
            setDomainChecked(true);
        }       
    };

    //Redirect to My Profile page
    const handleDoItLaterPath = () => {
        window.location.href = ROUTE_PATHS.PROFILE;
    };

    const handleSupportPortalNavigation = () => {
        window.open(supportPortalUrl, "_self");
    };

    const closeWindow = () => {
        if (location.pathname.includes("/web")) {
            handleSupportPortalNavigation();
        } else {
            window.close();
        }
    };

    return (
        <React.Fragment>
            <ClayCard className="step4">
                <ClayCardBody>
                    <span className="h1">{title}</span>
                    <div className="h3">{subTitle}</div>
                    {approvedServices.length > 0 && (
                        <ClayList>
                            {approvedServices.map((service) => (
                                <ServiceListItem
                                    key={service.title}
                                    status={service.status}
                                    title={service.title}
                                    info={service.info}
                                />
                            ))}
                        </ClayList>
                    )}
                    {type !== UPGRADE && !iSupplier && (
                        <div className="h3">{UNLOCK_ADDITIONAL_SERVICE_MESSAGE}</div>
                    )}
                    {type === UPGRADE && iSupplier && (
                        <div className="h3">{ISUPPLIER_SUBMIT_MESSAGE}</div>
                    )}
                    {pendingServices.length > 0 && (
                        <ClayList>
                            {pendingServices.map((service) => (
                                <ServiceListItem
                                    key={service.title}
                                    status={service.status}
                                    title={service.title}
                                    info={service.info}
                                    classList="mt-3 mb-0"
                                />
                            ))}
                        </ClayList>
                    )}
                    {disabledServices.length > 0 && (
                        <ClayList>
                            {disabledServices.map((service) => (
                                <ServiceListItem
                                    key={service.title}
                                    status={service.status}
                                    title={service.title}
                                    info={service.info}
                                />
                            ))}
                        </ClayList>
                    )}
                    {type === UPGRADE || iSupplier ? (
                        <>
                            {iSupplier && (
                                <ClayButton
                                    type="button"
                                    displayType="primary"
                                    className="btn-block"
                                    onClick={handleDoItLaterPath}
                                >
                                    {"Login"}
                                </ClayButton>
                            )}
                            <ClayButton
                                type="button"
                                displayType="secondary"
                                className="btn-block"
                                onClick={() => closeWindow()}
                            >
                                {"Close"}
                            </ClayButton>
                        </>
                    ) : (
                        <>
                            <ClayButton
                                type="button"
                                displayType="primary"
                                className="btn-block"
                                onClick={() => {
                                    initiateUserDomainCheck(userInfo?.email);
                                }}
                            >
                                {"Yes, I want to Build my Profile"}
                            </ClayButton>
                            <ClayButton
                                type="button"
                                displayType="secondary"
                                className="btn-block"
                                onClick={
                                    location.pathname === ROUTE_PATHS.UPGRADE
                                        ? closeWindow
                                        : handleSupportPortalNavigation
                                }
                            >
                                {"I'll do it later"}
                            </ClayButton>
                        </>
                    )}
                </ClayCardBody>
            </ClayCard>
        </React.Fragment>
    );
};
export default Step4;
