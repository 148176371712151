import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ClayCard from "@clayui/card";
import ClayButton from "@clayui/button";
import {
    getSaveStatus,
    updateSaveReceive,
    updateUserData,
    getProfileBuilderData,
    setCheckedValues,
    getUserInfoVo,
    getCheckedArray,
    getInitialValues,
    updateProfileType,
    updateServiceArrayList,
    // getTimezoneList,
} from "../../../../../store/Registration/profile-builder/slice";

import { updateServiceArray } from "../../../../../store/Registration/profile-builder/businessLogic/updateServiceArray";
import { USER_INFO_STRINGS } from "../constants";
import { ISetstepFunc } from "./interfaces/interfaces";

const Step6 = (props:ISetstepFunc) => {
    const dispatch = useDispatch();

    const saveStatus = useSelector(getSaveStatus);
    const profileData = useSelector(getProfileBuilderData);
    const userFilledInfo = useSelector(getUserInfoVo);
    const checkedArray = useSelector(getCheckedArray);
    // const timezoneOptions =useSelector(getTimezoneList)

    const initialObj = useSelector(getInitialValues);

    useEffect(() => {
        if (saveStatus.userEmail) {
            dispatch(updateServiceArrayList(updateServiceArray(saveStatus)));
            props.setStep(7);
        }
        dispatch(updateSaveReceive(""));
    }, [saveStatus]);

    const handleEditResponse = () => {
        if (profileData) {
            profileData.map((data) => {
                if (data.defaultChecked) {
                    dispatch(setCheckedValues(data.formNumber - 1));
                }
            });
        }

        props.setStep(5);
    };
    // const selectedTimezone = (data) => {
    //     let timeZone=""
    //     timezoneOptions.forEach((key) => {
    //         if(key.value===data){
    //             timeZone=key.label
    //         }
    //     });
    //     return timeZone.toString()
    // };
    const onUpgrade = () => {
        const defaultArr = [];
        if (profileData) {
            checkedArray.map((value, index) => {
                if (value) {
                    defaultArr.push(profileData[index].defaultChecked);
                }
            });
        }
        const allAreTrue = defaultArr.every((element) => element === true);
        if (allAreTrue) {
            dispatch(updateProfileType(""));
            props.setStep(7);
        } else {
            dispatch(updateUserData());
        }
    };
    const fieldConfig = {
        "0": {
            siteId: userFilledInfo.siteId ? userFilledInfo.siteId : "",
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            // timezone:initialObj.addressValues.timezone ? selectedTimezone(initialObj.addressValues.timezone) : "",
        },
        "1": {
            companyName: initialObj.addressValues.companyName
                ? initialObj.addressValues.companyName
                : "",
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            address: initialObj.addressValues.address ? initialObj.addressValues.address : "",
            address2: initialObj.addressValues.address2 ? initialObj.addressValues.address2 : "",
            zipcode: initialObj.addressValues.zipcode ? initialObj.addressValues.zipcode : "",
            city: initialObj.addressValues.city ? initialObj.addressValues.city : "",
            state: initialObj.addressValues.state ? initialObj.addressValues.state : "",
            countryName: initialObj.addressValues.countryName
                ? initialObj.addressValues.countryName
                : "",
        },
        "2": {
            companyName: initialObj.addressValues.companyName
                ? initialObj.addressValues.companyName
                : "",
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            address: initialObj.addressValues.address ? initialObj.addressValues.address : "",
            address2: initialObj.addressValues.address2 ? initialObj.addressValues.address2 : "",
            zipcode: initialObj.addressValues.zipcode ? initialObj.addressValues.zipcode : "",
            city: initialObj.addressValues.city ? initialObj.addressValues.city : "",
            state: initialObj.addressValues.state ? initialObj.addressValues.state : "",
            countryName: initialObj.addressValues.countryName
                ? initialObj.addressValues.countryName
                : "",
        },
        "3": {
            companyName: initialObj.addressValues.companyName
                ? initialObj.addressValues.companyName
                : "",
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            address: initialObj.addressValues.address ? initialObj.addressValues.address : "",
            address2: initialObj.addressValues.address2 ? initialObj.addressValues.address2 : "",
            zipcode: initialObj.addressValues.zipcode ? initialObj.addressValues.zipcode : "",
            city: initialObj.addressValues.city ? initialObj.addressValues.city : "",
            state: initialObj.addressValues.state ? initialObj.addressValues.state : "",
            countryName: initialObj.addressValues.countryName
                ? initialObj.addressValues.countryName
                : "",
        },
        "4": {
            companyName: initialObj.addressValues.companyName
                ? initialObj.addressValues.companyName
                : "",
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            address: initialObj.addressValues.address ? initialObj.addressValues.address : "",
            address2: initialObj.addressValues.address2 ? initialObj.addressValues.address2 : "",
            zipcode: initialObj.addressValues.zipcode ? initialObj.addressValues.zipcode : "",
            city: initialObj.addressValues.city ? initialObj.addressValues.city : "",
            state: initialObj.addressValues.state ? initialObj.addressValues.state : "",
            countryName: initialObj.addressValues.countryName
                ? initialObj.addressValues.countryName
                : "",
        },
        "5": {
            companyName: initialObj.addressValues.tapCompanyName
                ? initialObj.addressValues.tapCompanyName
                : initialObj.addressValues.companyName,
            ...(initialObj.addressValues.website && {website: initialObj.addressValues.website}),
            phone: initialObj.addressValues.phone ? initialObj.addressValues.phone : "",
            address: initialObj.addressValues.address ? initialObj.addressValues.address : "",
            address2: initialObj.addressValues.address2 ? initialObj.addressValues.address2 : "",
            zipcode: initialObj.addressValues.zipcode ? initialObj.addressValues.zipcode : "",
            city: initialObj.addressValues.city ? initialObj.addressValues.city : "",
            state: initialObj.addressValues.state ? initialObj.addressValues.state : "",
            countryName: initialObj.addressValues.countryName
                ? initialObj.addressValues.countryName
                : "",
            // timezone:initialObj.addressValues.timezone ? selectedTimezone(initialObj.addressValues.timezone) : "",
        },
    };
    const getLevel = (value) => {
        switch (value) {
            case "address":
                return USER_INFO_STRINGS[0];
            case "address2":
                return USER_INFO_STRINGS[1];
            case "city":
                return USER_INFO_STRINGS[2];
            case "phone":
                return USER_INFO_STRINGS[3];
            case "state":
                return USER_INFO_STRINGS[4];
            case "zipcode":
                return USER_INFO_STRINGS[5];
            case "serialNumber":
                return USER_INFO_STRINGS[6];
            case "customerId":
                return USER_INFO_STRINGS[7];
            case "broadcomcontact":
                return USER_INFO_STRINGS[8];
            case "siteId":
                return USER_INFO_STRINGS[9];
            case "companyName":
                return USER_INFO_STRINGS[10];
            case "jobTitle":
                return USER_INFO_STRINGS[11];
            case "countryName":
                return USER_INFO_STRINGS[12];
            case "website":
                return USER_INFO_STRINGS[13];
            case "broadcomSoftwareType":
                return USER_INFO_STRINGS[14];
            // case "timezone":
            //     return USER_INFO_STRINGS[14];
            default:
                return value;
        }
    };
    return (
        <React.Fragment>
            <ClayCard className="card1">
                <ClayCard.Body>
                    <span className="h1">Preview Information</span>
                </ClayCard.Body>
            </ClayCard>

            <ClayCard className="card2">
                <ClayCard.Body>
                    <p className="text-size-lg">
                        Validate your responses before clicking Confirm & Continue
                    </p>
                    {profileData.map((item, index) => {
                        return item.defaultChecked
                            ? item.isRequested && (
                                  <ClayCard className="card3" key={index}>
                                      <ClayCard.Body className="card3Body">
                                          <p className="title font-weight-bold">{item.title}</p>
                                          {Object.keys(fieldConfig[index]).map((key, i) => {
                                              return (
                                                  !(
                                                      key === "companyId" ||
                                                      key === "country" ||
                                                      key === "bsnAutoApproved" ||
                                                      key === "tapCompanyName" ||
                                                      key === "newTapDomain" 
                                                  ) && (
                                                      <div key={i}>
                                                          {getLevel(key)}: {fieldConfig[index][key]}
                                                      </div>
                                                  )
                                              );
                                          })}
                                      </ClayCard.Body>
                                  </ClayCard>
                              )
                            : item.isRequested && (
                                  <ClayCard className="card3" key={index}>
                                      <ClayCard.Body className="card3Body">
                                          <p className="title font-weight-bold">{item.title}</p>
                                          {Object.keys(item.data).map((key, index) => {
                                              return (
                                                  item.data[key] &&
                                                  !(
                                                      key === "companyId" ||
                                                      key === "country" ||
                                                      key === "bsnAutoApproved" ||
                                                      key === "tapCompanyName" ||
                                                      key === "newTapDomain"
                                                  ) && (
                                                      <div key={index}>
                                                          {getLevel(key)}:{" "}
                                                          {/* {key==="timezone" && selectedTimezone(item.data[key])} */}
                                                           {key!=="timezone" && (key==="companyName" && item.supportValue == 7 ? (item.data["tapCompanyName"] ? item.data["tapCompanyName"].toString() : 
                                                          item.data[key].toString()) : item.data[key].toString())}
                                                      </div>
                                                  )
                                              );
                                          })}
                                      </ClayCard.Body>
                                  </ClayCard>
                              );
                    })}
                    <ClayButton displayType="primary" className="btn-block" onClick={onUpgrade}>
                        {"Confirm & Continue"}
                    </ClayButton>
                    <ClayButton
                        displayType="secondary"
                        className="btn-block"
                        onClick={handleEditResponse}
                    >
                        {"Edit Response"}
                    </ClayButton>
                </ClayCard.Body>
            </ClayCard>
        </React.Fragment>
    );
};

export default Step6;
