import { ClayTooltipProvider } from "@clayui/tooltip";
import ClayButton from "@clayui/button";

import { IInfoButton } from "../../interfaces/interfaces";
import Icon from "../icons/Icon";

const InfoButton = (props: IInfoButton) => {
    const { info, align ,classname="p-0 pl-1 m-0" } = props;

    return (
        <ClayTooltipProvider>
            <ClayButton
                className={classname}
                displayType="link"
                data-tooltip-align={align ? align : "top"}
                title={info}
                tabIndex="-1"
            >
                <Icon type="infoIconBold" />
            </ClayButton>
        </ClayTooltipProvider>
    );
};

export { InfoButton };
