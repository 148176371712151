/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../../../client-src/store/hook";

import ClayButton from "@clayui/button";
import ClayForm, { ClayRadio, ClayRadioGroup } from "@clayui/form";
import { FormControl, InfoButton, RoundTickIcon, TextError } from "../../../../../../common-ui-modules";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    setVerifySupportVal,
    getSupportValSuccess,
    resetSupportValSuccess,
    getAttemptCountForSupportSiteId,
    getInitialValues,
    addressValuesReceived,
    getProfileBuilderData,
    fetchStates,
    stateVOReceived,
    getStateVo,
    getBroadcomSoftwareType,
    updateBroadcomSoftwareType,
    getholStatus,
    getSupportStatus,
    // getTimezoneList,
    // getTimezoneValueList,
} from "../../../../../../store/Registration/profile-builder/slice";
import ClayLayout from "@clayui/layout";
import { MAX_40, MIN_2, POSTAL_CODE_REGEX, SPECIAL_CHARS, PHONE_NO_REGEX, MAX_255 } from "../../constants";
import { getEnvVariables } from "../../../../../../store/global/global.slice";
import { getCountryList, getCountryValueList } from "../../../../../../store/Registration/registration/slice";
import { checkIfStatePresent } from "../../../../../../utils/utils";
import { InfoButtonContentRenderer } from "../../../../../../common-ui-modules/components/infoButtonContentRenderer/InfoButtonContentRenderer";
// import { useSelector } from "react-redux";

const Form1 = React.forwardRef((props, firstFormRef) => {
    const [showVerify, setShowVerify] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [initialFormObj, setInitialFormObj] = useState({});
    const [showInvalidMsg, setShowInvalidMsg] = useState(false);
    const [inputFieldcheck, setinputFieldcheck] = useState(false);
    const [bsgSelected, setBSGSelected] = useState(false);
    const [holSelected, setHOLSelected] = useState(false);
    const broadcomSoftwareType = useAppSelector(getBroadcomSoftwareType);
    const holStatus = useAppSelector(getholStatus);
    const supportStatus = useAppSelector(getSupportStatus);

    const dispatch = useAppDispatch();
    const envVariables = useAppSelector(getEnvVariables);

    const initialObj = useAppSelector(getInitialValues);
    const profileData = useAppSelector(getProfileBuilderData);
    const holUrl = "https://www.vmware.com/resources/hands-on-labs"
    let stateOptions: any = [];
    stateOptions = useAppSelector(getStateVo);
    const countryOptions = useAppSelector(getCountryList);
    const countryOptionValues = useAppSelector(getCountryValueList);
    const tooltipRenderer = (props) => {
        return (
            <div>To learn more about Hands-on Labs  <a href={holUrl} target="_blank" rel="noreferrer">click here.</a></div>
        )
    }

    useEffect(() => {
        let obj = {};
        const state = initialObj.addressValues.state;
        firstFormRef.current.setFieldValue("broadcomSoftwareType", broadcomSoftwareType);
        dispatch(addressValuesReceived({ broadcomSoftwareType: broadcomSoftwareType }));
        if (broadcomSoftwareType == "VMware Hands-on Labs Access") {
            setHOLSelected(true);
            setBSGSelected(false);
        } else if (broadcomSoftwareType == "Broadcom Software Access") {
            setHOLSelected(false);
            setBSGSelected(true);
        } else {
            setHOLSelected(false);
            setBSGSelected(false);
        }
        if (profileData[0].data) {
            obj = { ...profileData[0].data };
        } else {
            obj = {
                siteId: "", phone: initialObj.addressValues.phone, companyId: initialObj.addressValues.companyId,
                companyName: initialObj.addressValues.companyName, address: initialObj.addressValues.address, address2: initialObj.addressValues.address2,
                city: initialObj.addressValues.city, zipcode: initialObj.addressValues.zipcode, state: checkIfStatePresent(stateOptions, state) ? state : "", country: initialObj.addressValues.country
            };
        }
        setInitialFormObj(obj);
        firstFormRef.current.setFieldValue("phone", initialObj.addressValues.phone);
        firstFormRef.current.setFieldValue("broadcomSoftwareType", broadcomSoftwareType);
        if (broadcomSoftwareType == "VMware Hands-on Labs Access") {
            setHOLSelected(true);
            setBSGSelected(false);
        } else if (broadcomSoftwareType == "Broadcom Software Access") {
            setHOLSelected(false);
            setBSGSelected(true);
        } else {
            setHOLSelected(false);
            setBSGSelected(false);
        }
        setFormHOLValue();
        dispatch(addressValuesReceived({ broadcomSoftwareType: broadcomSoftwareType }));
    }, []);
    
    // const timezoneOptions =useSelector(getTimezoneList)
    // const timezoneValuelist =useSelector(getTimezoneValueList)
    // const timezoneInfo="Your preferred timezone will be used for case routing and correspondence with our support teams"

    const attemptCount = useAppSelector(getAttemptCountForSupportSiteId);
    const supportSiteIdSuccess = useAppSelector(getSupportValSuccess);
    const MY_SITEID_URL = envVariables?.ui.supportPortal + "/web/ecx/my-site-id";
    useEffect(() => {
        if (supportSiteIdSuccess === "" || supportSiteIdSuccess === null) {
            setIsValid(false);
            setShowVerify(false);
            setShowInvalidMsg(true);
        } else {
            setIsValid(true);
        }
    }, [supportSiteIdSuccess]);

    const setFormHOLValue = () => {
        const state = initialObj.addressValues.state;
        firstFormRef.current.setFieldValue("companyId", initialObj.addressValues.companyId);
        firstFormRef.current.setFieldValue("companyName", initialObj.addressValues.companyName);
        firstFormRef.current.setFieldValue("address", initialObj.addressValues.address);
        firstFormRef.current.setFieldValue("address2", initialObj.addressValues.address2);
        firstFormRef.current.setFieldValue("city", initialObj.addressValues.city);
        firstFormRef.current.setFieldValue("zipcode", initialObj.addressValues.zipcode);
        firstFormRef.current.setFieldValue(
            "state",
            checkIfStatePresent(stateOptions, state) ? state : "",
        );
        firstFormRef.current.setFieldValue("country", initialObj.addressValues.country);
    }


    const bsgValidationSchema = Yup.object().shape({
        siteId: Yup.string().required("*Site ID is required"),
        // .test("test-siteId", "Verify with correct Site Id to proceed",
        //   function(value) {
        //       if(supportSiteIdSuccess === '' || supportSiteIdSuccess === null){
        //         return false
        //       }else{
        //         return true
        //       }
        //   }),
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, "Maximum allowed characters is 40")
            .matches(PHONE_NO_REGEX, "Phone number is not valid"),
        // timezone: Yup.string()
        // .oneOf(timezoneValuelist, "*Timezone is required")
        // .required("*Timezone is required"),
    });
    const holValidationSchema = Yup.object().shape({
        phone: Yup.string()
            .required("*Phone Number is required")
            .min(8, "A minimum of 8 character is required")
            .max(40, MAX_40)
            .matches(PHONE_NO_REGEX, "Phone number is not valid"),
        address: Yup.string().required("*Address is required").min(2, MIN_2).max(255, MAX_255),
        address2: Yup.string()
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ0-9.,\s]+$/, SPECIAL_CHARS),
        city: Yup.string()
            .required("*City is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(/^[aA-zZ\s]+$/, "Numbers & special characters are not allowed"),
        zipcode: Yup.string()
            .required("*Zip/Postal Code is required")
            .min(2, MIN_2)
            .max(40, MAX_40)
            .matches(POSTAL_CODE_REGEX, SPECIAL_CHARS),
        country: Yup.string()
            .oneOf(countryOptionValues, "Country is required")
            .required("Country is required"),
        companyName: Yup.string().required("*Company is required"),
        companyId: Yup.string().required("*Company is required"),
    });
    const handleContactChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ phone: firstFormRef.current.values.phone }));
        setinputFieldcheck(true)
    };
    const handleBroadcomSoftwareType = (value) => {
        firstFormRef.current.setFieldValue("broadcomSoftwareType", value);
        dispatch(resetSupportValSuccess());
        dispatch(updateBroadcomSoftwareType(value));
        dispatch(addressValuesReceived({ broadcomSoftwareType: value }));
        if (value == "VMware Hands-on Labs Access") {
            firstFormRef.current.setFieldValue("siteId", "");
            setFormHOLValue();
            setHOLSelected(true);
            setBSGSelected(false);
        } else {
            firstFormRef.current.setFieldValue("companyId", "");
            firstFormRef.current.setFieldValue("companyName", "");
            firstFormRef.current.setFieldValue("address", "");
            firstFormRef.current.setFieldValue("address2", "");
            firstFormRef.current.setFieldValue("city", "");
            firstFormRef.current.setFieldValue("zipcode", "");
            firstFormRef.current.setFieldValue("state", "");
            firstFormRef.current.setFieldValue("country", "");
            setHOLSelected(false);
            setBSGSelected(true);
        }
    };
    const handleCompanySelectChange = (e, formik) => {
        formik.handleChange(e);
        firstFormRef.current.setFieldValue("companyId", e.currentTarget.value);
        dispatch(addressValuesReceived({ companyId: e.currentTarget.value }));
    };

    const handleCompanyChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ companyName: firstFormRef.current.values.companyName }));
    };
    const verifySupportVal = () => {
        const siteID = firstFormRef.current.values.siteId.trim();
        const data = { supportTypeId: "1", supportTypeVal: siteID };
        firstFormRef.current.setFieldValue("siteId", siteID);
        dispatch(setVerifySupportVal(data, "supportSiteId"));
    };
    const handleStateChange = (e, formik) => {
        firstFormRef.current.setFieldValue("state", e.currentTarget.value);
        dispatch(addressValuesReceived({ state: e.currentTarget.value }));
    };
    const handleOnChangeCountry = (e, formik) => {
        dispatch(stateVOReceived([{ label: "Select State", value: "" }]));
        firstFormRef.current.setFieldValue("stateName", "");
        firstFormRef.current.setFieldValue("country", e.currentTarget.value);
        firstFormRef.current.setFieldValue(
            "countryName",
            e.currentTarget.options[e.currentTarget.selectedIndex].text,
        );
        dispatch(addressValuesReceived({ country: e.currentTarget.value }));
        dispatch(
            addressValuesReceived({
                countryName: e.currentTarget.options[e.currentTarget.selectedIndex].text,
            }),
        );
        dispatch(fetchStates(e.currentTarget.value));
    };
    const validateStateIfPresent = (value) => {
        let errors = "";
        if (
            (stateOptions.length > 1 && value === "") ||
            (stateOptions.length > 1 && value && !stateOptions.map((i) => i.value).includes(value))
        ) {
            errors = "State is required.";
        }
        return errors;
    };
    const handleAddressChange = (value, formik, line) => {
        formik.handleBlur(value);
        if (line === 2) {
            dispatch(addressValuesReceived({ address2: firstFormRef.current.values.address2 }));
        } else {
            dispatch(addressValuesReceived({ address: firstFormRef.current.values.address }));
        }
    };

    const handleZipChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ zipcode: firstFormRef.current.values.zipcode }));
    };
    const handleCityChange = (value, formik) => {
        formik.handleBlur(value);
        dispatch(addressValuesReceived({ city: firstFormRef.current.values.city }));
    };

    const handleKeyDown = () => {
        setShowInvalidMsg(false);
        if (attemptCount !== 0) {
            setShowVerify(true);
            dispatch(resetSupportValSuccess());
        }
    };


    // const handleOnChangeTimezone = (e, formik) => { 
    //     firstFormRef.current.setFieldValue("timezone", e.currentTarget.value);
    //     dispatch(addressValuesReceived({ timezone: e.currentTarget.value }));
    //     setinputFieldcheck(true)
    // };

    const attempts = [
        "Invalid Site ID. 2 attempts remaining",
        "Invalid Site ID. 1 attempt remaining",
        "Sorry no attempts remaining",
    ];
    return (
        <React.Fragment>
            <Formik
                innerRef={firstFormRef}
                enableReinitialize={"true"}
                destroyOnUnmount={"false"}
                initialValues={initialFormObj}
                validationSchema={holSelected ? holValidationSchema : bsgValidationSchema}
                onSubmit={() => {
                    /**/
                }}
            >
                {(formik) => (
                    <ClayForm onSubmit={formik.handleSubmit}>
                        <ClayRadioGroup
                            inline selectedValue={broadcomSoftwareType}
                            defaultValue={supportStatus || holStatus ? "" : "Broadcom Software Access"}
                            onSelectedValueChange={(value: any) => handleBroadcomSoftwareType(value)}
                        >
                            <ClayRadio label="Broadcom Software Access" value="Broadcom Software Access" disabled={supportStatus} />
                            <InfoButton info={"Cases, Downloads, License Keys and more"} align={"top"} classname={"p-0 pl-1 pb-1 m-0 mr-2"} />
                            <ClayRadio label="VMware Hands-on Labs Access" value="VMware Hands-on Labs Access" disabled={holStatus} />
                            <InfoButtonContentRenderer info={tooltipRenderer} align={"top"} classname={"p-0 pl-1 pb-1 m-0 mr-2"} />
                        </ClayRadioGroup>

                        {bsgSelected ? <>
                            <ClayLayout.Row className="supportSiteIdrow">
                                <ClayLayout.Col size={8}>
                                    <label htmlFor="siteId">Site ID</label>
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                            <ClayLayout.Row className="">
                                <ClayLayout.Col size={8}>
                                    <FormControl
                                        control="text"
                                        type="text"
                                        name="siteId"
                                        autoComplete={"off"}
                                        placeholder="Enter Site ID"
                                        onKeyDown={handleKeyDown}
                                        onPaste={handleKeyDown}
                                        disabled={attemptCount === 0 ? true : false}
                                    />
                                    {formik.errors.siteId
                                        ? null
                                        : attemptCount !== 3 &&
                                        showInvalidMsg && (
                                            <div className="has-error">
                                                <TextError>
                                                    {attemptCount === 2
                                                        ? attempts[0]
                                                        : attemptCount === 1
                                                            ? attempts[1]
                                                            : attemptCount === 0 && attempts[2]}
                                                </TextError>
                                            </div>
                                        )}
                                </ClayLayout.Col>
                                <ClayLayout.Col
                                    size={4}
                                    className=""
                                    style={{ lineHeight: "1.9375rem" }}
                                >
                                    {showVerify && !isValid ? (
                                        <ClayButton
                                            displayType="primary"
                                            onClick={() => verifySupportVal()}
                                        >
                                            {"Verify"}
                                        </ClayButton>
                                    ) : isValid ? (
                                        <RoundTickIcon color="success" />
                                    ) : supportSiteIdSuccess === null ? (
                                        ""
                                    ) : (
                                        ""
                                    )}
                                </ClayLayout.Col>
                            </ClayLayout.Row>

                            <div style={{ margin: "10px 0 8px", display: "block" }}>
                                <a
                                    href={MY_SITEID_URL}
                                    target="_blank"
                                    style={{ color: "#005C8A" }}
                                    rel="noreferrer"
                                >
                                    Need help locating your Site ID
                                </a>
                            </div>
                        </> : ""}
                        {holSelected ? <>
                            {initialObj &&
                                initialObj.company &&
                                initialObj.company.fieldType === "select" ? (
                                <FormControl
                                    control="select"
                                    name="companyId"
                                    label="Company"
                                    options={initialObj.company.options}
                                    onChange={(e) => handleCompanySelectChange(e, formik)}
                                    disabled={
                                        initialObj &&
                                        initialObj.company &&
                                        initialObj.company.isDisabled
                                    }
                                />
                            ) : (
                                <FormControl
                                    control="text"
                                    name="companyName"
                                    label="Company"
                                    onBlur={(e) => handleCompanyChange(e, formik)}
                                    disabled={
                                        initialObj &&
                                        initialObj.company &&
                                        initialObj.company.isDisabled
                                    }
                                />
                            )}
                        </> : ""}
                        {bsgSelected || holSelected ?
                            <FormControl
                                control="text"
                                type="text"
                                name="phone"
                                label="Phone Number"
                                autoComplete={"off"}
                                placeholder="Enter Phone Number"
                                onBlur={(e: any) => handleContactChange(e, formik)}
                            //disabled={(initialObj.isReturningUser && initialObj.addressValues.phone)}
                            /> : ""}
                        {holSelected ? <><FormControl
                            control="text"
                            type="text"
                            name="address"
                            label="Address Line 1"
                            autoComplete={"off"}
                            placeholder="Enter the address line 1"
                            onBlur={(e) => handleAddressChange(e, formik)}
                        />
                            <FormControl
                                control="text"
                                type="text"
                                name="address2"
                                label="Address Line 2"
                                autoComplete={"off"}
                                placeholder="Enter the address line 2"
                                onBlur={(e) => handleAddressChange(e, formik, 2)}
                            />
                            <FormControl
                                control="select"
                                name="country"
                                label="Country"
                                options={countryOptions}
                                onChange={(e) => handleOnChangeCountry(e, formik)}
                                onBlur={() => formik.setFieldTouched("country", true)}
                            />
                            {stateOptions.length > 1 && (
                                <FormControl
                                    control="select"
                                    name="state"
                                    label="State"
                                    autoComplete={"off"}
                                    options={stateOptions}
                                    validate={validateStateIfPresent}
                                    onChange={(e) => handleStateChange(e, formik)}
                                />)}

                            <FormControl
                                control="text"
                                type="text"
                                name="city"
                                label="City"
                                autoComplete={"off"}
                                placeholder="Enter the city name"
                                onBlur={(e) => handleCityChange(e, formik)}
                            />
                            <FormControl
                                control="text"
                                type="text"
                                name="zipcode"
                                label="Zip/Postal Code"
                                autoComplete={"off"}
                                placeholder="Enter the zip/postal code"
                                onBlur={(e) => handleZipChange(e, formik)}
                            />
                        </> : ""}
                    </ClayForm>
                )}
            </Formik>
        </React.Fragment>
    );
});

export default Form1;
