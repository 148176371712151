import React, { useState, useEffect } from "react";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayButton from "@clayui/button";
import { useSelector } from "react-redux";
import {
    getErrorMsg,
    getMailRequestSent,
    getModalOpenStatus,
    getOtpValidationStatus,
    getSendOtp,
    getValidateOtp,
    loadResetValidateOtp,
    loadValidateOtp,
    resetOtpValidationFailed,
    resetSendOtp,
    sendForgetPassword,
} from "../../../../../../store/Registration/forgot-password/slice";
import Icon from "../../../../../../common-ui-modules/components/icons/Icon";
import OtpInput from "react-otp-input";
import { useAppDispatch } from "../../../../../../store/hook";
import { INVALID_OTP_MSG, MAIL_SENT_SUCCESS, OTP_ERROR_MSG, OTP_SEND_SUCCESS } from "../constants";
import CommonLayout from "../../../../../../components/CommonLayout/CommonLayout";
import { ROUTE_PATHS } from "../../../../../../utils/constants/routeConstants";
import ModelPopUp from "../../../common/model/ModelPopUp";

const OTPVerification = (props: any) => {
    const openModal = useSelector(getModalOpenStatus);
    const [otp, setOtp] = useState("");
    const [userEmail, setUserEmail] = useState(props.email);
    const [otpError, setOtpError] = useState(false);
    const [otpErrorMessage, setOtpErrorMessage] = useState(OTP_ERROR_MSG);
    const validatedOtp = useSelector(getValidateOtp);
    const otpReceived = useSelector(getSendOtp);
    const otpValidationStatus = useSelector(getOtpValidationStatus);
    const mailRequestSent = useSelector(getMailRequestSent);
    const errorMsg = useSelector(getErrorMsg);

    const dispatch = useAppDispatch();

    useEffect(() => {
        !!userEmail && setUserEmail(mungeEmailAddress(userEmail));
    });

    const mungeEmailAddress = (s: string) => {
        const i = s.indexOf("@");
        const startIndex = (i * 0.3) | 0;
        const endIndex = (i * 0.9) | 0;
        return (
            s.slice(0, startIndex) +
            s.slice(startIndex, endIndex).replace(/./g, "*") +
            s.slice(endIndex)
        );
    };

    //TODO
    const styles = {
        inputStyle: {
            width: "100%",
            height: "4rem",
            fontSize: "1.4rem",
            border: "1px solid #c1c1c1",
            borderRadius: "4px",
        },
        focusStyle: {
            border: "1px solid #70767c",
            outline: "none",
        },
    };

    useEffect(() => {
        if (!!validatedOtp && !validatedOtp.success) {
            setOtpError(true);
            setOtpErrorMessage(validatedOtp.data.successMessage);
        } else if (!!validatedOtp && validatedOtp.success) {
            dispatch(sendForgetPassword(props.email, ""));
            dispatch(loadResetValidateOtp());
        }
    }, [validatedOtp]);

    useEffect(() => {
        setOtp("");
    }, [mailRequestSent, errorMsg]);

    useEffect(() => {
        if (otpValidationStatus) {
            setOtpError(true);
            setOtpErrorMessage(INVALID_OTP_MSG);
        }
    }, [otpValidationStatus]);

    const handleVerifyContinue = () => {
        if (otp.length === 6) {
            setOtpError(false);
            dispatch(resetOtpValidationFailed());

            //call to otp validation
            dispatch(loadValidateOtp(otp, props.captchaToken));
        } else {
            setOtpError(true);
        }
    };

    //OTP Error/Invalid
    const RenderOtpError = () => {
        return (
            <ClayForm.Group className="form-group-sm has-error">
                <ClayForm.FeedbackItem>{otpErrorMessage}</ClayForm.FeedbackItem>
            </ClayForm.Group>
        );
    };

    const backHandler = (props: {
        email?: any;
        setCompanyOptions?: (arg0: any) => void;
        setVisible?: (arg0: boolean) => void;
        setStep: any;
        handleCaptcha: any;
        captchaToken?: any;
    }) => {
        dispatch(loadResetValidateOtp());
        setOtpError(false);
        dispatch(resetOtpValidationFailed());
        window.location.href = ROUTE_PATHS.FORGOTPASSWORD;
    };

    return (
        <React.Fragment>
            <CommonLayout>
                <ClayCard>
                    <ClayCard.Body>
                        <a
                            className="pe-cursor"
                            onClick={() => {
                                backHandler(props);
                            }}
                        >
                            <Icon type="backArrowIcon" />
                        </a>
                        <br />
                        <br />

                        <span className="h1">Verify your Email Address</span>

                        <div className="text-size-lg">
                            {"Enter the verification code sent to"} <br /> <b>{userEmail}</b>
                        </div>
                        <ClayForm>
                            <div className="my-5">
                                <OtpInput
                                    value={otp}
                                    onChange={(otp: React.SetStateAction<string>) => {
                                        setOtp(otp);
                                        setOtpError(false);
                                        setOtpErrorMessage(OTP_ERROR_MSG);
                                    }}
                                    numInputs={6}
                                    separator={<span>&nbsp;&nbsp;</span>}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={styles.inputStyle}
                                    focusStyle={styles.focusStyle}
                                />
                                {otpError && <RenderOtpError />}
                            </div>
                            {/* <p className="form-instruction mb-4">
                            Didn't receive the code?{" "}
                            <a href={void 0} className="pe-cursor" onClick={resendOTP}>
                                Resend
                            </a>
                        </p> */}
                            {/* <p className="mb-5 form-instruction">
                            Having trouble?{" "}
                            <a href={KB_OTP_ISSUE} target="_blank">
                                Click here
                            </a>{" "}
                            for help.
                        </p> */}
                            <ClayButton
                                displayType="primary"
                                className="btn-block"
                                disabled={mailRequestSent || errorMsg}
                                onClick={handleVerifyContinue}
                            >
                                {"Verify & Continue"}
                            </ClayButton>
                            <br />
                        </ClayForm>
                    </ClayCard.Body>
                </ClayCard>
            </CommonLayout>
            {otpReceived && openModal && (
                <ModelPopUp
                    icon="roundTick"
                    msg={OTP_SEND_SUCCESS}
                    modelCloseHandler={() => dispatch(resetSendOtp())}
                />
            )}
            {(mailRequestSent || errorMsg) && openModal && (
                <ModelPopUp
                    icon="roundTick"
                    msg={MAIL_SENT_SUCCESS}
                    modelCloseHandler={() => {
                        window.location.href = ROUTE_PATHS.FORGOTPASSWORD;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default OTPVerification;
