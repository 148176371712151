import React from "react";
import { TextError } from "../../../../../common-ui-modules/components/index";
import { ClayTooltipProvider } from "@clayui/tooltip";
import ClayList from "@clayui/list";
import Icon from "../../../../../common-ui-modules/components/icons/Icon";
import { InfoButtonContentRenderer } from "../../../../../common-ui-modules/components/infoButtonContentRenderer/InfoButtonContentRenderer";

const ServiceListItem = (props: any) => {
    const { status, title, info, classList = "" } = props;
    const holUrl = "https://www.vmware.com/resources/hands-on-labs"
    const tooltipRenderer = (props) => {
        return (
            <div>To learn more about Hands-on Labs  <a href={holUrl} target="_blank" rel="noreferrer">click here.</a></div>
        )
    }
    

    return (
        <React.Fragment>
            <ClayList.Item flex className={classList}>
                <ClayList.ItemField>
                    {status === "pending" ? (
                        <Icon type="roundPendingIcon" />
                    ) : (
                        <Icon type="roundTickIcon" color={status} />
                    )}
                </ClayList.ItemField>
                <ClayList.ItemField expand>{title}</ClayList.ItemField>
                {info && (
                    <ClayTooltipProvider >
                        <ClayList.ItemField
                            className="pe-cursor"
                            data-tooltip-align="top"
                            title={info}
                        >
                            <Icon type="infoIconBold" />
                        </ClayList.ItemField>
                    </ClayTooltipProvider>
                )}
                {
                    title==="VMware Hands-on Lab"&&(
                        <InfoButtonContentRenderer info={tooltipRenderer} align={"top"} classname={"pe-cursor py-0 px-2"} />
                    )
                }
            </ClayList.Item>
            {status === "pending" && (
                <TextError>
                    <span className="pending-msg">Request sent for approval</span>
                </TextError>
            )}
        </React.Fragment>
    );
};

export default ServiceListItem;
